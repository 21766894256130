.login-main-container {
    width: 90%;
    max-width: 785px;
    background-color: white;
    border-radius: 10px;
    padding: min(calc(100vw*32/1194), 32px) min(calc(100vw*45/1194), 45px);
    position: relative;
    margin: 0 auto;
    z-index: 1;
}

.login-main-fields {
    margin-top: min(calc(100vw*40/1194), 40px);
    display: flex;
    justify-content: space-between;
}

.mb-fields { 
    flex-direction: column;
    width: 100%;
    gap: 15px;
}

.login-info {
    margin-top: min(calc(100vw*60/1194), 60px);
    margin-left: min(calc(100vw*20/1194), 20px);
    margin-bottom: min(calc(100vw*20/1194), 20px);
}

.login-info-text {
    color: white;
    font-size: min(calc(100vw*16/1194), 16px);
    font-weight: 500;
}

.mb-fields .login-main-btn {
    width: 100%;
    height: 40px;
    border-radius: 32px;
    font-size: 14px;
    font-weight: 700;
    padding: 5px;
    background-color: #73992A;
    color: #fff;
    border: 1px solid;
}

.login-main-btn-group {
    gap: 10px;
    display: flex;
    width: 100%;
}

.login-main-btn-group .login-main-btn {
    background-color: #fff;
    color: #73992A;
}

.mb-fields .login-main-label { 
    font-size: 14px;
}


.mb-fields .login-main-field { 
    width: 100%;
    height: 32px;
    border-radius: 20px;
    align-items: center;
}

.mb-fields input {
    font-size: 14px;
    padding-left: 15px;
}

.mb-fields .login-main-img {
    width: 24px;
    height: 24px;
    background-size: 70%;
    background-position: center center;
}


.mb-fields .login-main-field-cont { 
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.login-content {
    /* width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    padding-top: 10%;
}