.signup-form-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 40px;
}

.signup-form-footer-mobile {
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
}