.leaflet-container {
	border-radius: 15px;
	height: 100%;
	width: auto;
    z-index: 0;
}
.leaflet-control-attribution {
	display: none;
}

.map {
	border: 2px solid #f3f3f3;
    border-radius: 15px;
    padding: 10px;
    margin: 20px 0;
    position: relative;
}

.map .title {
    position: absolute;
    background-color: #fff;
    top: -15px;
    left: 10px;
    padding: 0 5px;
}