.ms-ttl-left {
	display: flex;
	align-items: center;
}
.ms-ttl-left p {
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
}


.mb-ms-ttl-left {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;

}