.form-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 40px;
    & div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.form-header-mobile {
    gap: 0px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
}

.form-title {
    text-align: left;
    padding: 0 0;
    margin: 0 0;
    font-size: 2.5rem;
    font-weight: bold;
    font-weight: 750;
    color: var(--accent);   
}