.container {
    margin: 0 auto;
}

.login-header-content-left {
    cursor: pointer;
    z-index: 2;
}

.login-header-content-right {
    width: 100%;
}

.login-header {
	/* position: fixed; */
	width: 100%;
	z-index: 10;
}

.login-header-content {
    width: 100%;
	height: 80px;
    /* min-height: 50px; */
	display: flex;
	padding: 0 min(calc(100vw*60/1194), 60px) 0 min(calc(100vw*60/1194), 60px);
	align-items: center;
	justify-content: space-between;
}

.hd__bg {
	position: absolute;
	width: 100%;
	height: min(calc(100vw*80/1194), 80px);
	background-color: rgb(0, 0, 0);
	z-index: -1;
	opacity: 0;
}


.content {
	height: min(calc(100vw*80/1194), 80px);
	display: flex;
	padding: 0 min(calc(100vw*60/1194), 60px) 0 min(calc(100vw*60/1194), 60px);
	align-items: center;
	/* justify-content: space-between; */
}

.login-header-logo{
	height: 41px;
	/* margin: 0px auto; */
	vertical-align: middle;
}

.login-header-content-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: calc(-1 * min(calc(100vw*125/1194), 125px));
}

.login-header-content-nav-item {
    align-items: center;
    margin-right: min(calc(100vw*24/1194), 24px);
    list-style-type: none;
}

.login-header-content-nav-list {
    display: flex;    
}

.login-header-content-nav-item-content {
    display: flex;    
    font-weight: 500;
    font-size: min(calc(100vw*16/1194), 16px);
    color: white;
    line-height: min(calc(100vw*16/1194), 16px);
    cursor: pointer;
    white-space: nowrap;
    font-family: 'Inter', sans-serif;
    text-decoration: none;
  }