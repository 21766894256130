.popup-modal {
    width: 100vw;
    height: 100vh;
    border-radius: 16px;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    transform: scale(0);
    z-index: 100;
}

.popup-active {
    width: 100vw;
    height: 100vh;
    border-radius: 16px;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    transform: scale(1);
    z-index: 100;
}

.popup-container {
    border-radius: 10px;
    /* background-color: var(--whiteC); */
    background-color: white;
    position: relative;
    overflow: hidden; 
    padding: 5px 10px;
}

.popup-close {
    width: 24px;
    height: 24px;
    top: 5px;
    right: 5px;
    /* width: min(calc(100vw*24/1194), 24px); */
    /* height: min(calc(100vw*24/1194), 24px); */
    position: absolute;
    /* top: min(calc(100vw*24/1194), 24px); */
    /* right: min(calc(100vw*24/1194), 24px); */
    cursor: pointer;
}
