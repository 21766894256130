.phone-hint {
    margin-top: 2px;
    font-size: 0.675rem;
    text-indent: 20px;
}

.phone-block {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
}