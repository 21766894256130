.menu {
	height: 100%;
}

.mb-menu{
	padding: 0px 10px;
}

.mb-menu .ico-r {
	border: none;
	margin: unset;
}
