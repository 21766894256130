.mess-cnt-title {
	align-items: center;
	color: #fff;
	font-style: normal;
	font-weight: bold;
	display: flex;
	justify-content: space-between;
	padding: 10px;
	height: unset;
	font-size: 18px;
	font-weight: 600;
	border-radius: 10px 10px 0px 0px;
	background-color:#E5E5E5;
}
.mess-cnt-title p {
	background-position: left center;
	background-repeat: no-repeat;
	background-size: 20px;
}
