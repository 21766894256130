.tbl-com-control {
	display: flex;
	align-items: center;
}


.mb-control {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 0 5px;
}