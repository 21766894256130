.login-main-field{
    width: min(calc(100vw * 330 / 1194), 330px);
    height: min(calc(100vw * 32 / 1194), 32px);
    border-radius: min(calc(100vw * 20 / 1194), 20px);
    background-color: #ECECEC;
    display: flex;
    justify-content: space-between;
    margin-top: min(calc(100vw * 6 / 1194), 6px);
}

.login-main-label {
    display: block;
    font-size: min(calc(100vw * 16 / 1194), 16px);
    font-weight: 700;
}

.login-main-input {    
    font-size: min(calc(100vw * 14 / 1194), 14px);
    line-height: min(calc(100vw * 24 / 1194), 24px);
    padding: min(calc(100vw * 4 / 1194), 4px) min(calc(100vw * 15 / 1194), 15px);
    border: none;
    outline: none;
    background: none;
    flex: 1;
}

.login-main-input:active {
    border: none;
    outline: none;
}

.login-main-img {
    width: min(calc(100vw * 24 / 1194), 24px);
    margin:  min(calc(100vw * 8 / 1194), 8px)  min(calc(100vw * 7 / 1194), 7px);
    background: no-repeat;
    background-size: contain;
}
