.login-app {
    margin: min(calc(100vw*15/1194), 15px) auto min(calc(100vw*21/1194), 21px);
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    width: 80%;
}

.login-app-name {
    color: white;
    font-size: min(calc(100vw*16/1194), 16px);
}

.login-app-img {
    width: min(calc(100vw*48/1194), 48px);
}

.login-app-download {
    width: min(calc(100vw*786/1194), 786px);
    background-color: white;
    padding: min(calc(100vw*32/1194), 32px) min(calc(100vw*45/1194), 45px);
}

.login-app-title {
    color: #000;
    font-size: 24px;
    font-weight: bold;
}

.login-app-line {
    display: flex;
    justify-content: center;
    margin-top: min(calc(100vw*26/1194), 26px);
}

.login-app-href:not(:first-child) {
    margin-left: min(calc(100vw*154/1194), 154px);
}

.login-app-store {
    width: 135px;
    height: 40px;
}

.login-app-btn {
    width: 191px;
    height: 30px;
    background-color: #73992A;
    border: none;
    color: white;
    font-size: 16px;
    border-radius: 32px;
    cursor: pointer;
}

.mb-app {
}

.mb-app .login-app-title {
    font-size: 20px;

}

/* .mb-app .login-app-btn {

} */