.login-header-burger-drop {
    height: auto;
    position: absolute;
    background-color:#F0F0F0;
    top: 60px;
    right: 20px;
    width: 150px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px 11px rgba(0,0,0,.1);
    z-index: 1000;
    overflow: auto;
    max-height: 350px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.login-header-burger-drop-item {
    display: flex;
    align-items: center;
    gap: 5px;
}

.login-header-burger-drop-item a {
    text-decoration: none;
}

