
.balance {
	min-width: 200px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	gap: clamp(0.3125rem, -0.2083rem + 0.6944vw, 0.625rem);
	display: inline-flex;
	align-items: center;
}

.bal-mb {
	flex-direction: column;
	align-items: start;
	gap: 15px;
	width: 180px;
	min-width:unset;
}


.skin-hdr-r-drop-bal {
	width: 100%;
	background-color: #fff;
	padding: 7px;
	display: flex;
	justify-content: center;
	gap: 5px;
	border-radius: 100px;
}

.skin-hdr-r-drop-tar {
	width: 100%;
	background-color:#73992A;
	padding: 7px;
	display: flex;
	justify-content: center;
	gap: 5px;
	border-radius: 100px;
	color: #ffff;
	font-size: 14px;
}

