.clr-t1 {
	color: #fff;
	background-color: #73992A;
}
.clr-t2 {
	color: #fff;
	background-color: #5ABA0C;
}
.clr-t3 {
	color: #000;
	background-color: #F3F3F3;
}
.clr-t4 {
	color: #EA2828;
}
