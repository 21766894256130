.cmp-format-about-title {
    font-size: 14px;
    font-weight: 600;
    /* color: var(--title); */
}

.cmp-format-about-str {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    padding-top: 5px;
}

.cmp-format-about-table {
    display: flex;
    flex-direction: column;
	gap: 10px;
    font-size: 14px;
}

.cmp-format-about-table_row{
    display: flex;
    justify-content: space-between;
}