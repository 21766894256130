.mb-pg-menu { 
    padding-top: 10px;
    width: 100%;
}

.mb-pg-menu.off {
    width: 0px;
}


.mb-pg-menu .pg-mn-head {
    padding-left: 0px;
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    padding-top: 20px;
    padding-bottom: 30px;
}

.mb-pg-menu .ico-r {
    border: none;
    margin: unset;
}


.pg-menu span {
    width: 20px;
    height: 20px;
}

.pg-mn-head span {
    width: 30px;
    height: 30px;
}

.mb-pg-menu p {
    font-size: 18px;
}