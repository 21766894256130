.login-qr-main {
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-top: min(calc(100vw*15/1194), 15px);
}
.login-title-qr {
    color: #595959;
    font-size: 16px;
    font-weight: 500;
}

.login-qr-container {
    width: min(calc(100vw*500/1194), 500px);
    background-color: white;
    padding: min(calc(100vw*32/1194), 32px) min(calc(100vw*45/1194), 45px);
}


.login-qr-title {
    color: #73992A;
    font-size: min(calc(100vw*24/1194), 24px);
    font-weight: bold;
}

.login-qr {
    width: min(calc(100vw*420/1194), 420px);
    height: min(calc(100vw*420/1194), 420px);
    margin-top: min(calc(100vw*36/1194), 36px);
}