
.color input {
	padding: 2px 5px;
}
.color {
	/* padding: 5px 2px; */
}

.input-color input {
	background-color: #f3f3f3;
	border: none;
	border-radius: 20px;
	padding: 4px 20px;
	height: 30px;
}
.input-color input:disabled {
	background-color: transparent;
	padding: 0;
}