.container {
    width: min(calc(100vw*785/1194), 785px);
    background-color: white;
    border-radius: min(calc(100vw*32/1194), 32px);
    margin: min(calc(100vw*150/1194), 150px) auto 0 auto;
    padding: min(calc(100vw*32/1194), 32px) min(calc(100vw*45/1194), 45px);    
}

.fields {
    margin-top: min(calc(100vw*40/1194), 40px);
    display: flex;
    justify-content: space-between;
}