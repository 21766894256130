.company-container {
    display: flex;
    flex-direction: column;
    padding: 4px;
    & .suggest-container {
        width: 100%;
    }
    & .suggest-input {
        background-color: var(--bg);
    }
}

.company-header {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.company-datatype-content {
    display: flex;
    flex-direction: row;
    text-align: left;
    font-size: 0.8rem;
    margin: 0px 12px;
}

.company-datatype-name {
    font-weight: 700;
    margin-right: 4px;
    max-width: 100px;
}

.company-title {
    font-size: 0.875rem;
    font-weight: 700;
}

.company-body {
    display: flex;
    flex-direction: column;
    background-color: var(--fieldbg);
    justify-content: space-between;
    align-items: start;
    gap: 6px;
    line-height: 0.9rem;
    border-radius: 20px;
    padding: 16px 12px;
    & .field-container, & .dropdown-container {
        width: 100%;
    }
    & .field-input, & .dropdown-input {
        background-color: var(--bg);
    }
    & .field-title {
        font-size: 0.8rem;
    }
}