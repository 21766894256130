.dct-cnt-main {
	overflow-y: auto;
	max-height: 70vh;
}
.dct-cnt-main th,
.dct-cnt-main td {
	vertical-align: top;
}
.dct-cnt-main td {
}

@media screen and (max-width: 600px) {
	.dct-cnt-main {
		max-height: 80vh;
	}
}