.space {
	width: 100vw;
	height: 100vh;
}
.skin-shadow {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	opacity: 0.2;
}
.skin-wait {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 120px;
	height: 120px;
	margin-top: -60px;
	margin-left: -60px;
}

.logout {
	color: #5aba0c;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	border-color: #5aba0c;
	border: 2px solid;
	width: 70px;
	border-radius: 20px;
	margin: 5px auto;
}

.logout p {
	background-repeat: no-repeat;
	background-size: 12px;
	padding-right: 5px;
	background-position: right center;
	/* background-image: url("skin/img/logout.svg"); */
}
/* .space .phone {
	display: none;
} */
/* @media screen and (max-width: 600px) {
	.space .phone {
		display: flex;
		text-align: center;
		position: absolute;
		top: 50%;
		font-weight: bold;
		flex-direction: column;
		width: calc(100vw - 10px)
	}
	.space .cmp {
		display: none;
	}
	
} */