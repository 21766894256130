.field-title {
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 12px;
}

.field-container {
    /* z-index: 1; */
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4px;
}

.field-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--fieldbg);
    border-radius: 40px;
    & input {
        width: 100%;
        margin: 4px 8px;
        margin-right: 0;
        height: 24px;
        background-color: transparent;
        border: none;
        text-indent: 4px;
    }
    & input::placeholder {
        text-indent: 4px;
        color: var(--placeholder);
    }
    & :focus {
        outline: none;
    }
    & input:hover {
        outline: none;
    }
    & > img {
        pointer-events: none;
    }
    & .img-container {
        margin-right: 12px;
    }

}

.img-container {
    padding: 2px;
    width: 24px;
    height: 24px;
    & img {
        width: 100%;
        height: 100%;
    }
}