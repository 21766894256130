.form-code {
    padding: 4px;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.code-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--fieldbg);
    padding: 4px;
    padding-left: 8px;
    border-radius: 40px;
    & input {
        width: 46px;
        height: 20px;
        background-color: transparent;
        border: none;
        text-indent: 4px;
        
    }
    & input:focus {
        outline: none;
    }
    & .img-container {
        padding: 0px 4px;
        width: 24px;
        height: 24px;
        & > img {
            width: 100%;
            height: 100%;
        }
    }
}

.green-circle-bound {
    padding: 4px;
    border-radius: 100%;
    outline: 1px solid var(--accent);
}

.red-circle-bound {
    padding: 4px;
    border-radius: 100%;
    outline: 1px solid var(--errtext);
}

.code-status {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    margin-right: 4px;
    & .altBtn {
        font-size: 12px;
    };
    & .img-container {
        display: flex;
        width: 24px;
        height: 24px;
        & > img {
            width: 100%;
            height: 100%;
        }
    };
    & .code-sidebar-timeleft {
        font-size: 0.8rem;
    };
}

.code-check-failed {
    display: flex;
    padding: 4px 14px;
    gap: 4px;
    & .img-container {
        width: 16px;
    };
    & .errorText {
        margin: 0 0;
        display: flex;
        align-items: center;
        color: var(--errtext);
        font-size: 0.8rem;
    }
}