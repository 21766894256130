.form-block {
    display: flex;
    flex-direction: column;
    text-align: left;
    line-height: 0.7rem;
    gap: 6px;
    width: 310px;
}

.form-block-mobile {
    /* max-width: 240px; */
}