.alt-img-container {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 16px;
    height: 16px;
    & > img {
        width: 100%;
        height: 100%;
    }
}

.normalBtn {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    text-wrap: nowrap;
    font-size: 16px;
    font-weight: 600;
    color: var(--accent);
    text-decoration: none;
    text-align: center;
    justify-content: center;
    background-color: var(--btnbg);
    padding: 9px 26px;
    border-radius: 50px;
    border: 1px solid var(--accent);
    & img {
        margin: 0 6px;
        transform: translate(0, 2px);
    }
	& span {
		color: var(--accent);
		font-weight: 700;
	}
}
.normalBtn:hover {
	cursor: pointer;
    background-color: #73992a;
	& span {
		color: white;
	}
    
}

.reverseBtn {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    text-wrap: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 48px;
    margin-right: 4px;
    padding: 2px 8px;
    font-size: 0.7rem;
    font-weight: 400;
    color: var(--bg);
    background-color: var(--accent);
    text-decoration: none;
	gap: 5px;
    & img {
        margin-right: 4px;
        filter: brightness(0) invert(1);
    }
    & span {
        user-select: none;
        cursor: pointer;
		color: var(--bg)
    }
}
.disabledReverseBtn {
    background-color: var(--placeholder);
    cursor: default;
    & span {
        cursor: default;   
    }
}

.altBtn {
    cursor: pointer;
    display: flex;
    gap: 4px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-wrap: nowrap;
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--accent);
    text-decoration: none;
}

.disabledBtn {
    cursor: default;
    color: var(--placeholder);
}

.errorBtn {
    & > span {
        color: var(--errtext);
    }
}