.login-main-apps {
    background: rgba(0, 0, 0, 0.60);
    width: 90%;
    max-width: 785px;
    margin: 0 auto;
    position: relative;
    top: calc(-1 * min(calc(100vw*32/1194), 32px));
    padding-top: min(calc(100vw*32/1194), 32px);
    border-radius: 10px;
}

.login-main-apps-title{
    color: white;
    text-align: center;
    font-size: min(calc(100vw*16/1194), 16px);
    font-weight: 600;
    margin-top: min(calc(100vw*6/1194), 6px);
}

.login-main-apps-line{
    display: flex;
    justify-content: space-between;
}

.mb-apps {
    width: 90%;
    top: -30px;
    padding-top: 40px;
    padding-bottom: 10px;
}

.mb-apps .login-main-apps-title{ 
    font-size: 16px;
}

.mb-apps .login-main-apps-line {
    padding-top: 10px;
}

.mb-apps .login-app-img {
    width: 48px;
}

.mb-apps .login-app-name {
    font-size: 16px;
    /* max-width: 60px; */
}