.mb-crumb-drop {
    height: auto;
    position: absolute;
    background-color:#F0F0F0;
    width: 200px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px 11px rgba(0,0,0,.1);
    z-index: 1000;
    overflow: auto;
    max-height: 350px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    right: 0px;
    top: 25px;
}

.mb-crumb-list {
    position: relative;
}