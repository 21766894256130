.command form {
	display: flex;
	align-items: center;
}
.comm-mb {
	padding-top: 10px;
    display: flex;
    justify-content: center;
    height: 70px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
}

.comm-mb form {
	justify-content: center;
}

.comm-mb input {
	width: 320px;
	background-color: #F0F0F0;
}
.command button {
	width: 24px;
	height: 24px;
	margin-right: 10px;
	border: 0;
	border-radius: 50%;
	cursor: pointer;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 14px;
}
.command input {
	padding-top: 5px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 5px;
	border: 0;
	border-radius: 20px;
}

.mb input {
	background-color: #F0F0F0;
	min-width: 300px
}
