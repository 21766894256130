.skin-main-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.skin-main-item {
    padding: 10px;
    margin: 5px;
    border: 1px solid #73992A;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}


.skin-main-item-content {
    padding-bottom: 5px;
    border-bottom: 1px solid #E6F5DB;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.skin-main-item-content:nth-last-child(-n+1){
    border: none;
}

.skin-main-item-content-header {
    font-size: 16px;
    font-weight: 600;
}

.skin-main-item-content-text {
    font-size: 14px;
}