.cmp-group.border {
	border: 2px solid #f3f3f3;
	border-radius: 15px;
	padding: 10px;
	margin: 20px 0;
	position: relative;
}

.cmp-group .title {
	padding: 0 17px;
}

.cmp-group.border .title {
	position: absolute;
	background-color: #fff;
	top: -15px;
	left: 10px;
	padding: 0 5px;
}


.mb-cmp-group .cmp-field {
	padding-top: 10px;
}

.mb-cmp-group {
	display: flex !important;
	flex-direction: row !important;
	margin: unset !important;
	flex-wrap: wrap;
	margin: 20px 0px !important;
}

.mb-cmp-group .mb-field {
	/* width: 50%; */
}

/* .mb-cmp-group .input-base {
	width: 100%;
}*/

/* .mb-cmp-group input {
	max-width: 100%;
}  */

.mb-field .input-file {
	width: unset !important;
}

