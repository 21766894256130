.info-content {
	position: absolute;
	top: 70px;
	right: 70px;
	bottom: 70px;
	max-width: calc(100% - 140px);
	padding: 30px;
	background-color: #fff;
	border-radius: 20px;

}
.info-cnt-close {
	position: absolute;
	top: 0;
	right: -55px;
	width: 50px;
	height: 50px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 40%;
	background-color: #fff;
	border-radius: 50%;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.15);
	cursor: pointer;
}

.mb-info-content {
	width: 80vw;
	max-width: 700px;
	right: 50px;
}

.mb-info-content .info-cnt-close {
	width: 30px;
	height: 30px;
	right: -40px
}


.mb-info-content .info-content-title{
	font-size: 18px;
}

.mb-info-content-main {
	margin-top: 10px;
	border-radius: 10px;
	overflow: auto;
	max-height: 95%;
}

.mb-info-content-item {
	border-bottom: 1px solid #E6F5DB;
	padding: 10px;
	display: flex;
	flex-direction: column;
	row-gap: 5px;
}

.mb-info-content-item-topic {
	font-size: 16px;
	font-weight: 600;
	max-width: 100%;
	overflow-wrap: break-word;
}

.mb-info-content-item-title {
	font-size: 14px;
	font-weight: 600;
	max-width: 100%;
	overflow-wrap: break-word;
}

.mb-info-content-item-value {
	font-size: 12px;
	max-width: 100%;
	overflow-wrap: break-word;
}