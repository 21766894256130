.cmp-button {
	color: #5aba0c;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	border-color: #5aba0c;
	margin-top: 20px;
}

.btn p {
	background-repeat: no-repeat;
	background-size: 12px;
	padding-right: 5px;
	background-position: right center;
}