.mess-content {
	position: relative;
	background-color: #fff;
	width: 300px;
	border-radius: 10px;

}
.mess-cnt-close {
	top: 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 40%;
	border-radius: 50%;
	cursor: pointer;
	
	min-width: 30px;
	min-height: 30px;
	width: 30px;
	height: 30px;
	position: unset;
	box-shadow: none;
	background-color: #fff;
	fill-opacity: 0;
}


.mb-message .mess-cnt-close {
	min-width: 30px;
	min-height: 30px;
	width: 30px;
	height: 30px;
	position: unset;
	box-shadow: none;
	background-color: #fff;
	fill-opacity: 0;
}
