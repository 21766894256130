.crumb {
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  /* background-color: silver; */
}

.crumb .breadcrumb {
  cursor: pointer;
  
}

.mb-breadcrumb {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: unset;
  display: inline-block;
}

.mb-breadcrumb:nth-child(-n+3) {
  max-width: 200px;
  white-space: nowrap;
  overflow: unset;
  text-overflow: unset;
}


.crumb .breadcrumb + .breadcrumb:before {
  /* padding: 0 0px; */
  content: "/\00a0";
}

.crumb .breadcrumb:last-child {
  color: #73992A;
  pointer-events: none;
}

.crumb .breadcrumb:hover {
  text-decoration: underline;
}

.mb-crumb { 
  padding: 0;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mb-crumb span {
  font-size: 14px;
}


/* @media screen and (max-width: 600px) {

  .mb-breadcrumb {
    max-width: 170px;
  }
  
}

@media screen and (max-width: 400px) {

  .mb-breadcrumb {
    max-width: 120px;
  }
  
} */

