.dropdown-frame {
    border-top: 1px solid var(--accent);
    z-index: 1001;
    width: auto;
    height: fit-content;
    background-color: var(--bg);
    border-radius: 0 0 16px 16px;
    font-size: 0.8rem;
    line-height: 0.8rem;
    overflow-y: scroll;
    min-height: 75px;
    height: max-content;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    & ul {
        cursor: default;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        list-style: none;
        padding-inline-start: 0;
        margin-block-start: 0;
        margin-block-end: 0;
        color: var(--base);
        & li {
            padding: 6px 12px;
            font-size: 0.75rem;
        }
        & li:hover {
            background-color: var(--fieldbg);
            color: var(--accent);
        }
        & .altBtn {
            width: fit-content;
            height: auto;
            justify-content: left;
            color: var(--base);
            & :hover {
                color: var(--accent);
            }
        }
    }
}

.options-title {
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 12px;
}

.inline-option-input {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    & input {
        cursor:default;
    }
}

.options-container {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4px;
}

.options-input {
    max-height: 30px;
    display: flex;
    flex-direction: column;
    background-color: var(--fieldbg);
    border-radius: 16px;
    & input {
        margin: 4px 8px;
        margin-right: 0;
        margin-left: 12px;
        height: 20px;
        width: 100%;
        background-color: transparent;
        border: none;
        direction: rtl;
        text-align: left;
    }
    & input::placeholder {
        color: var(--placeholder);
        direction: ltr;
        text-align: left;
    }
    & :focus {
        outline: none;
        direction: ltr;
        text-align: left;
    }
    & input:hover {
        outline: none;
    }
    & > img {
        pointer-events: none;
    }
    & .img-container {
        margin-right: 12px;
    }
}

.dropdown-input:hover {
    cursor: text;
}

.img-container {
    padding: 2px;
    width: 24px;
    height: 24px;
    & img {
        width: 100%;
        height: 100%;
    }
}