.login-main-btn {
	width: min(calc(100vw * 220 / 1194), 220px);
	border-radius: min(calc(100vw * 32 / 1194), 32px);
	color: #73992a;
	font-size: min(calc(100vw * 16 / 1194), 16px);
	font-weight: 700;
	border: min(calc(100vw * 1 / 1194), 1px) solid #73992a;
	padding: min(calc(100vw * 10 / 1194), 10px) min(calc(100vw * 5 / 1194), 5px);
}

.login-main-btn[type='submit'], .login-main-btn[type='button']{
	-webkit-appearance: none;
	appearance: none;
	-moz-appearance: none;
    background-color: #EFEFEF;
}

.login-main-btn:hover {
	cursor: pointer;
	background-color: #73992a;
	color: white;
}
