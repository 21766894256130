.cmp-field {
	display: inline-block;
	padding: 0 5px;
	vertical-align: bottom;
	max-width: 100%;
}

.cmp-field:has(img) {
	vertical-align: top;
	display: inline-block;
	padding: 0 5px;
}

::placeholder {
	color: #bfbfbf;
}


/* .mb-field input{ 
	width: unset !important;
} */