dialog::backdrop {
  backdrop-filter: blur(10px);
}
.cmp-dialog {
  /* Стиль для всего диалогового окна */
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background-color: transparent;
}

.cmp-dialog-close {
	position: absolute;
	right: 20px;
	top: 10px;
	z-index: 99999;
}
.cmp-dialog-back {
	position: absolute;
	top: 5px;
	left: 5px;
	/* width: unset !important; */
	/* background: none !important; */
	z-index: 10;
	flex-grow: 1;
}
.cmp-dialog-content {
	width: calc(100% - 20px);
	height: calc(100% - 20px);
	background-color: white;
	padding: 10px;
	border-radius: 28px;
}
.cmp-dialog-header {
  /* Стиль для заголовка диалогового окна */
  display: flex;
  justify-content: center;
  align-items: center;
}

.cmp-dialog-header h2 {
  /* Стиль для заголовка */
  margin: 0;
  text-align: center;
}

.cmp-dialog-header button {
  /* Стиль для кнопки закрытия */
  border: none;
  cursor: pointer;
}

.cmp-dialog-body {
  /* Стиль для содержимого диалогового окна */
  padding: 10px;
  min-width: 400px;
  min-height: 200px;
  max-height: 80vh;
overflow: auto;
}
.cmp-dialog-content  .cmp-load {
	width: 100%;
	height: 100%;
}

.noscroll {
	overflow: hidden;
}

/*Мобильная версия*/
.cmp-dialog-mb {
	/* Стиль для всего диалогового окна */
	position: fixed;
	top: 0;
	left: 0;
	/* height: 100%; */
	width: 100vw;
	max-width: 100vw;
	max-height: 100%;
	border-radius: unset;
	border: none;
	padding: 0px;
	background-color: white;
	/* overflow: hidden; */
}

.cmp-dialog-mb-header {
	/* padding-top: 10px; */
	padding: 5px 0px;
	/* display: flex; */
	text-align: center;
	position: fixed;
	width: 100%;
	background-color: white;
	/* justify-content: space-between; */
	align-items: center;
	z-index: 99;
}
.cmp-dialog-mb-header >h2 {
	flex-grow: 1.2;
}

.cmp-dialog-mb-content {
	width: 100%;
}
.cmp-dialog-mb-body {
	padding-top: 40px;
	padding-inline: 20px;
}
.ios {
	/* height: calc(100vh + 20px); */
	height: 100vh;
}
.android {
	height: 100vh;
}

.mb-confirm { 
	max-height: 90px;
	min-height: 90px;
	width: 70vw;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	overflow: hidden;
	border-radius: 12px;
}

.mb-confirm .cmp-dialog-mb-content{
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	justify-content: space-between;
	max-height: 90px
}

  
.mb-confirm-addr { 
	max-height: 240px;
	min-height: 240px;
	width: 90vw;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	overflow: hidden;
	border-radius: 12px;
}

.mb-confirm-addr .cmp-dialog-mb-content{
	max-height: 240px
}
  
.mb-confirm-addr .cmp-dialog-back {
	display: none;
}
  
.mb-confirm-addr .cmp-dialog-mb-body {
	padding: 10px;
}
  
.mb-confirm-addr .cmp-footer-menu-del-address {
	margin: 0 auto;
	margin-top: 45px;
}
  
.mb-confirm-addr .cmp-dialog-mb-header {
	padding-top: 10px;
	text-align: center; 
}
.mb-confirm .cmp-dialog-mb-header {
	padding-top: 10px;
	text-align: center; 
}

  
.mb-confirm .cmp-dialog-back {
	display: none;
}
  
.mb-confirm h2 {
	font-size: 20px;
}
  
.mb-confirm .cmp-dialog-mb-body {
	padding: 30px 10px;
}
  