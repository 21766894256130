.page {
	position: relative;
	width: 100vw;
	height: 100vh;
	min-height: 500px;
	/* min-width: 1000px; */
}
.main {
	height: calc(100% - 50px - 40px - 50px);
	padding-top: 20px;
}
.sandbox {
	position: relative;
	height: 100%;
	margin-left: 300px;
	padding-right: 20px;
	transition-property: margin-left;
	transition-duration: 0.5s;
}
.sandbox.full {
	margin-left: 60px;
}

.sandbox.off {
	display: none;
}


.mb-sandbox {
	margin-left: 0px;
}

.mb-sandbox.full {
	margin-left: 0px;
	padding-right: 0px;
}

.mb-sandbox .tbl-head {
	padding-top: 10px;
	height: unset;
}

.mb-sandbox .tbl-command {
	padding-top: 10px;
	flex-direction: column;
	align-items: flex-start;
	height: unset;
}
.mb-sandbox .frm-command {
	padding-top: 10px;
	flex-direction: column;
	align-items: flex-start;
	height: unset;
	overflow: unset;
}
.mb-sandbox .tbl-main {
	padding-top: 10px;
	padding-bottom: 70px;
	height: unset;
}
.mb-sandbox .title{
	padding-bottom: 10px;
}
.mb-sandbox .form-main-body {
	margin-top: 10px;
	margin-left: 5px;
	margin-right: 5px;
	border: none;
	padding: unset;
	overflow: unset;
	height: unset;
	padding-bottom: 70px;
}
.mb-sandbox .tbl-page {
	padding-top: 10px;
}
.mb-sandbox .form-page {
	padding-top: 10px;
}

.mb-sandbox .ico-r {
	border: none;
}

.mb-sandbox .tbl-head-com-name {
	font-size: 18px;
}
.mb-sandbox .form-head-ttl-com-name {
	font-size: 18px;
}

.mb-sandbox .master {
	padding-top: 10px;
	padding-bottom: 70px;
	height: unset;
}

.mb-sandbox .ms-title { 
	display: flex;
	flex-direction: column;
	height: unset;
	align-items: flex-start;
	gap: 10px;
}

.mb-sandbox .ms-ttl-left p {
	font-size: 18px;
}
.mb-sandbox .ms-ttl-right {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.mb-sandbox .master-content {
	margin: 0 5px;
}
