.skin-hdr-r-message-count {
    position: absolute;
    right: -10px;
    top: -10px;
    background-color: #CFEBD3;
    width: 25px;
    height: 25px;
    padding: 5px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.skin-hdr-r-message,
.skin-hdr-r-message-mb {
    position: relative;
}

.skin-hdr-r-message-list {
    height: auto;
    position: absolute;
    background-color: white;
    top: 40px;
    right: 0px;
    width: 400px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px 11px rgba(0,0,0,.1);
    z-index: 2;
    /* overflow: auto; */
    max-height: 350px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.skin-hdr-r-message-list-mb {
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	position: absolute;
	background-color: white;
}
.skin-hdr-r-message-mb > p {
	margin-left: 40px;
	line-height: 30px;
}

.cmp-msg-list-item-date {
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
}