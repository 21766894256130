@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
:root {
    --accent: #73992A;
    --bg: #F9F9F9;
    --btnbg: #EFEFEF;
    --fieldbg: #ECECEC;
    --placeholder: #C1C1C1;
    --errtext: #F14D4D;
    --shadow: #00000040;
    --base: black;
}

* {
    font-family: Inter;
}

body {
    /* background-color: rgba(100, 100, 100); */
}

.form-wrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    background-color: var(--bg);
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 60px;
    padding-right: 60px;
    border-radius: 13px;
    box-shadow: 0px 4px 13.6px var(--shadow);
	margin: 5% auto;
}

.form-wrapper-mobile {
    padding: 25px;
}

.errorText {
    margin: 4px 12px;
    color: var(--errtext);
    font-size: 0.7rem;
}