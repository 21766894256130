.cmptbl-main-body {
	min-height: 50px;
	max-height: 350px;
	/* overflow-y: auto; */
	/* overflow-x: none; */
}
.cmptbl-main-body > table {
	width: 100%;
	table-layout: fixed;
}
.cmptbl-main-body > table tr {
	cursor: pointer;
}
.cmptbl-main-body > table tr:hover {
	color: #5aba0c;
}
.cmptbl-main-body > table td {
	vertical-align: top;
	padding-top: 3px;
	padding-left: 5px;
	padding-right: 5px;
	padding-bottom: 3px;
}
