.login-main-title{
    display: flex;
    align-items: center;
}

.login-main-title-img {
    width: min(calc(100vw*54/760), 54px);
    height: min(calc(100vw*54/760), 54px);
}

.login-main-title-txt {
    color: #73992A;
    font-size: clamp(1.75rem, 1.568rem + 0.7767vw, 2.5rem);
    font-weight: 700;
    margin-left: min(calc(100vw*16/1194), 16px);
}