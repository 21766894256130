.ms-footer {
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 50px;
}

.ms-footer .btn {
	color: #73992A;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	border-color: #73992A;
}
.ms-footer .btn p {
	background-repeat: no-repeat;
	background-size: 12px;
	padding-right: 20px
}
